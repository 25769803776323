import { cssMap, keyframes } from '@compiled/react';
import type { ComponentType, FC, ReactNode } from 'react';
import React, { useCallback, useRef } from 'react';
import type { WrappedComponentProps } from 'react-intl-next';

import LinkItem from '@atlaskit/menu/link-item';
import { Box, Grid, Inline, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import NodeIcon from '@atlaskit/icon/core/node';

import { MenuLinkItem } from '@atlassian/navigation-system/side-nav/menu-link-item';

import { LazyEmojiComponentLoader } from '@confluence/emoji-title';
import { invalidEmojiRegex } from '@confluence/emoji-title/entry-points/helpers';
import { ContentTreeIconLoader as ContentTreeIcon } from '@confluence/icons/entry-points/ContentTreeIcon';
import type { ContentTreeTypes } from '@confluence/icons/entry-points/contentTreeTypes';
import { includesValidContentTreeType } from '@confluence/icons/entry-points/contentTreeTypes';
import { useIsNav4Enabled } from '@confluence/nav4-enabled';
import { ConditionalTreeItemTooltip } from '@confluence/page-tree';
import { ConditionalInlineRename } from '@confluence/page-tree/entry-points/InlineRename';
import type { IconItemType } from '@confluence/page-tree/entry-points/PageTreeAfterIcon';
import { PageTreeAfterIcon } from '@confluence/page-tree/entry-points/PageTreeAfterIcon';

import { useIsAdvancedSearchInTreeEnabled } from '../useIsAdvancedSearchInTreeEnabled';

type SpaceViewsListItemProps = {
	title: string;
	url: string;
	onItemClick?: (id: string, index?: number) => void;
	isSelected: boolean;
	item: IconItemType;
	spaceKey: string;
	isSuperAdmin: boolean;
	highlight?: boolean;
	displayEmoji?: string;
	contentType?: string;
	isHoverPageCardOptedIn?: boolean;
	closeAllHoverPageCards?: () => void;
	setOpenQuickActionsId: (state: string | null) => void;
	isQuickActionsOpen: boolean;
	isQuickActionsFocused: boolean;
	setFocusedQuickActionsId: (focusedQuickActionsId: string | null) => void;
	isEditingTitleId: string | null;
	setIsEditingTitleId: (isEditingTitleId: string | null) => Promise<void>;
	onEnterHoverTarget?: (params: {
		itemID: string;
		isQuickActionsFocused: boolean;
		hoverPageCardTriggerRef: React.RefObject<HTMLDivElement>;
	}) => void;
	onLeaveHoverTarget?: () => void;
	onBlurHoverTarget?: (e) => void;
	shouldRenderAfterIcon?: boolean;
	index?: number;
	renderTitle?: ({ title }: { title: string }) => ReactNode;
};

const EmojiOrContentIcon = ({
	isContentTreeIconVisible,
	contentType,
	iconColor,
	displayEmoji,
	isNav4Enabled = false,
}) => {
	const isEmbedWithoutEmoji = contentType === 'embed' && !displayEmoji;

	if (isContentTreeIconVisible && !displayEmoji) {
		return (
			<Box
				as="span"
				xcss={[isNav4Enabled ? contentIconWrapperNav4Styles : contentIconWrapperStyles]}
			>
				<ContentTreeIcon type={contentType as ContentTreeTypes} label="" color={iconColor} />
			</Box>
		);
	} else if ((displayEmoji && !invalidEmojiRegex.test(displayEmoji)) || isEmbedWithoutEmoji) {
		return (
			<LazyEmojiComponentLoader
				emoji={displayEmoji}
				height={16}
				context="spaceViews"
				wrapper={isNav4Enabled ? Nav4EmojiWrapper : EmojiWrapper}
				contentType={contentType}
			/>
		);
	} else {
		return null;
	}
};

const HIGHLIGHT_FADE = keyframes({
	from: {
		background: token('color.background.accent.blue.subtlest'),
	},
	to: {
		background: 'none',
	},
});

const styles = cssMap({
	root: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
		'&&': {
			textDecoration: 'none',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			borderRadius: '3px',
			minHeight: '36px',
			backgroundColor: 'transparent',
			height: '36px',
			padding: '6px 4px 6px 8px',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
			span: {
				columnGap: '0px',
			},
			color: token('color.text'),
			'&::before': {
				content: 'none',
			},
			'&:visited, &:active, &:focus, &:hover': {
				color: token('color.text.subtle'),
				textDecoration: 'none',
			},
			'&:active, &:focus': {
				textDecoration: 'none',
			},
			'&:hover': {
				color: token('color.text'),
			},
			animation: 'none',
			'--display-after-icon': 'none',
			'--opacity-after-icon': '0%',
			'&:hover, &:focus-within': {
				'--display-after-icon': 'flex',
				'--opacity-after-icon': '100%',
				backgroundColor: token('color.background.neutral.subtle.hovered'),
			},
		},
	},
	isForceHoverBackground: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
		'&&': {
			'--display-after-icon': 'flex',
			'--opacity-after-icon': '100%',
		},
	},
	isHighlighted: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
		'&&': {
			animation: `${HIGHLIGHT_FADE} 2s linear`,
		},
	},
	isQuickActionsFocused: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
		'&&': {
			'--display-after-icon': 'flex',
			'--opacity-after-icon': '100%',
		},
	},
	isAdvancedSearchInTreeEnabled: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
		'&&': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
			'& strong': {
				backgroundColor: token('color.background.accent.yellow.subtler'),
				padding: token('space.025'),
				// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage
				borderRadius: token('border.radius'),
			},
		},
	},
	isSelected: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
		'&&': {
			backgroundColor: token('color.background.selected'),
			'&:visited, &:active, &:focus, &:hover': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
				color: `${token('color.text.selected')} !important`,
			},
			'&:hover, &:focus-within': {
				backgroundColor: token('color.background.selected.hovered'),
			},
		},
	},
});

const EmojiWrapperStyles = xcss({
	display: 'flex',
	flexShrink: 0,
	alignItems: 'center',
	marginRight: 'space.075',
});

const EmojiWrapper: ComponentType<{ children?: React.ReactNode }> = ({ children }) => {
	return (
		<Inline as="span" xcss={EmojiWrapperStyles}>
			{children}
		</Inline>
	);
};

const Nav4EmojiWrapperStyles = xcss({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexShrink: 0,
});

const Nav4EmojiWrapper: ComponentType<{ children?: React.ReactNode }> = ({ children }) => {
	return (
		<Inline as="span" xcss={Nav4EmojiWrapperStyles}>
			{children}
		</Inline>
	);
};

export const SpaceViewsListItem: FC<SpaceViewsListItemProps & WrappedComponentProps> = ({
	title,
	url,
	onItemClick,
	isSelected,
	item,
	displayEmoji = '',
	contentType,
	spaceKey,
	isSuperAdmin = false,
	highlight = false,
	isHoverPageCardOptedIn = false,
	closeAllHoverPageCards = () => {},
	setOpenQuickActionsId,
	isQuickActionsOpen,
	isQuickActionsFocused,
	setFocusedQuickActionsId,
	isEditingTitleId,
	setIsEditingTitleId,
	onEnterHoverTarget = () => {},
	onLeaveHoverTarget = () => {},
	onBlurHoverTarget = () => {},
	shouldRenderAfterIcon,
	index,
	renderTitle = ({ title }) => title,
}) => {
	const hoverPageCardTriggerRef = useRef<HTMLDivElement>(null);

	const { isAdvancedSearchInTreeEnabled } = useIsAdvancedSearchInTreeEnabled();
	const isNav4Enabled = useIsNav4Enabled();
	const onClick = useCallback(() => {
		onItemClick && onItemClick(item.id, index);
		closeAllHoverPageCards();
	}, [onItemClick, item.id, index, closeAllHoverPageCards]);

	const iconColor = isSelected ? token('color.icon.selected') : undefined;

	const isContentTreeIconVisible =
		contentType && includesValidContentTreeType(contentType, isNav4Enabled);
	const isLiveDoc = contentType === 'page' && item.data.subType === 'live';
	const iconContentType = isLiveDoc ? 'liveDoc' : contentType;

	if (isNav4Enabled) {
		return (
			// eslint-disable-next-line jsx-a11y/no-static-element-interactions
			<div
				ref={hoverPageCardTriggerRef}
				onBlur={onBlurHoverTarget}
				onMouseEnter={() =>
					onEnterHoverTarget({
						itemID: item.id.toString(),
						isQuickActionsFocused,
						hoverPageCardTriggerRef,
					})
				}
				onMouseLeave={onLeaveHoverTarget}
				onFocus={() =>
					onEnterHoverTarget({
						itemID: item.id.toString(),
						isQuickActionsFocused,
						hoverPageCardTriggerRef,
					})
				}
			>
				<MenuLinkItem
					testId="space-views-list-item"
					onClick={onClick}
					href={url}
					isSelected={isSelected}
					elemBefore={<NodeIcon label="" />}
				>
					<Grid alignItems="center" columnGap="space.075" templateColumns="minmax(0, auto) 1fr">
						<EmojiOrContentIcon
							isContentTreeIconVisible={isContentTreeIconVisible}
							contentType={iconContentType}
							iconColor={iconColor}
							displayEmoji={displayEmoji}
							isNav4Enabled
						/>
						<Box as="span" xcss={titleStyles}>
							{renderTitle({ title })}
						</Box>
					</Grid>
				</MenuLinkItem>
			</div>
		);
	}

	return (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<div
			ref={hoverPageCardTriggerRef}
			onBlur={onBlurHoverTarget}
			onMouseEnter={() =>
				onEnterHoverTarget({
					itemID: item.id.toString(),
					isQuickActionsFocused,
					hoverPageCardTriggerRef,
				})
			}
			onMouseLeave={onLeaveHoverTarget}
			onFocus={() =>
				onEnterHoverTarget({
					itemID: item.id.toString(),
					isQuickActionsFocused,
					hoverPageCardTriggerRef,
				})
			}
		>
			<ConditionalInlineRename
				id={item.id}
				displayEmoji={displayEmoji}
				title={title}
				isEditingTitle={isEditingTitleId === item.id}
				setIsEditingTitleId={setIsEditingTitleId}
				setFocusedQuickActionsId={setFocusedQuickActionsId}
				updateContentTreeItem={noop}
			>
				<ConditionalTreeItemTooltip
					title={title}
					isHoverPageCardOptedIn={isHoverPageCardOptedIn}
					isQuickActionsOpen={isQuickActionsOpen}
				>
					<LinkItem
						testId="space-views-list-item"
						css={[
							styles.root,
							isSelected && styles.isSelected,
							isQuickActionsOpen && styles.isForceHoverBackground,
							highlight && styles.isHighlighted,
							isQuickActionsFocused && styles.isQuickActionsFocused,
							isAdvancedSearchInTreeEnabled && styles.isAdvancedSearchInTreeEnabled,
						]}
						onClick={onClick}
						// @ts-ignore
						href={url}
						isSelected={isSelected}
						iconAfter={
							shouldRenderAfterIcon && (
								<PageTreeAfterIcon
									setFocusedQuickActionsId={setFocusedQuickActionsId}
									isSuperAdmin={isSuperAdmin}
									isQuickActionsOpen={isQuickActionsOpen}
									setOpenQuickActionsId={setOpenQuickActionsId}
									setIsEditingTitleId={setIsEditingTitleId}
									closeAllHoverPageCards={closeAllHoverPageCards}
									spaceKey={spaceKey}
									item={item}
									displayTitle={title}
									hideFolder
								/>
							)
						}
						iconBefore={
							<>
								<NodeIcon label="" />
								<EmojiOrContentIcon
									isContentTreeIconVisible={isContentTreeIconVisible}
									contentType={iconContentType}
									iconColor={iconColor}
									displayEmoji={displayEmoji}
								/>
							</>
						}
					>
						{renderTitle({ title })}
					</LinkItem>
				</ConditionalTreeItemTooltip>
			</ConditionalInlineRename>
		</div>
	);
};

const noop = () => {};

const titleStyles = xcss({
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
});

const contentIconWrapperStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	paddingRight: 'space.075',
});

const contentIconWrapperNav4Styles = xcss({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexShrink: 0,
});
